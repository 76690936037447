import axios from 'axios';

async function getRandomImage(tag = 'random') {
    const baseUrl = 'https://api.unsplash.com/photos/random';
    const auth_key = `Client-ID ${process.env.VUE_APP_UNSPLASH_ACCESS_KEY}`;

    try {
        const response = await axios.get(baseUrl, {
            headers: {
                Authorization: auth_key
            },
            params: {
                query: tag,
                orientation: 'landscape',
                content_filter: 'high'
            }
        });

        console.log('img_Src_response', response);
        return {
            url: response.data.urls.regular,
            photographer: response.data.user.name,
            photographerUrl: response.data.user.links.html
        };

    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
}

export const AVAILABLE_TAGS = [
    'nature',
    'animals',
    'landscape',
    'architecture',
    'people',
    'technology',
    'abstract',
    'food',
    'shapes',
    'colors'
];

export { getRandomImage };