<template>
  <div id="app">
    <AppTitle class="app-title"/>
    <WelcomeUser />
  </div>
</template>

<script>
import AppTitle from './components/AppTitle.vue'
import WelcomeUser from './components/WelcomeUser.vue'

export default {
  name: 'App',
  components: {
    AppTitle,
    WelcomeUser
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #0f0e17 0%, #1a1827 100%);
}

#app {
  font-family: 'Montserrat', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #e2e8f0;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.app-title {
  margin-bottom: 2rem;
}

/* Updated separator with mysterious glow */
.app-title::after {
  content: '';
  display: block;
  width: 150px;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(157, 113, 234, 0.5), transparent);
  margin: 1rem auto;
  box-shadow: 0 0 10px rgba(157, 113, 234, 0.3);
}
</style>
