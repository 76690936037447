<template>
  <div class="welcome-container">
    <h1>{{ generatedTarget }}</h1>
    
    <div v-if="sessionState === 'initial'" class="tags-container">
      <button 
        @click="isTagsExpanded = !isTagsExpanded" 
        class="expand-button"
      >
        {{ isTagsExpanded ? 'Hide' : 'Show' }} Image Categories
        <span class="arrow" :class="{ 'expanded': isTagsExpanded }">▼</span>
      </button>
      
      <div 
        class="tags-content" 
        :class="{ 'expanded': isTagsExpanded }"
      >
        <div class="tags-grid">
          <label 
            v-for="tag in AVAILABLE_TAGS" 
            :key="tag" 
            class="tag-label"
            :class="{ 'selected': selectedTag === tag }"
          >
            <input
              type="radio"
              :value="tag"
              v-model="selectedTag"
              name="tag"
              class="tag-input"
            >
            <span class="tag-text">{{ tag.charAt(0).toUpperCase() + tag.slice(1) }}</span>
          </label>
          <label class="tag-label" :class="{ 'selected': selectedTag === null }">
            <input
              type="radio"
              :value="null"
              v-model="selectedTag"
              name="tag"
              class="tag-input"
            >
            <span class="tag-text">Random</span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="showImage" class="image-container">
      <img :src="imageSrc" alt="Random image" class="target-image" />
      <a 
        :href="photographerUrl" 
        target="_blank" 
        rel="noopener noreferrer" 
        class="image-source"
      >
        Photo by {{ photographer }} on Unsplash
      </a>
    </div>
    
    <button @click="handleButtonClick" class="action-button">
      {{ buttonTitle }}
    </button>
  </div>
</template>

<script>
import { getRandomImage, AVAILABLE_TAGS } from '@/services/apiUtils';

export default {
  name: 'WelcomeUser',
  data() {
    return {
      generatedTarget: "Welcome User",
      buttonTitle: "Generate Target ID",
      imageSrc: null,
      showImage: false,
      sessionState: 'initial', // 'initial', 'generated', 'revealed'
      photographer: '',
      photographerUrl: '',
      selectedTag: null, // Changed default to null for random
      AVAILABLE_TAGS, // Add this to make tags available in template
      isTagsExpanded: false,
    }
  },
  methods: {
    async targetGenerator() {
      let firstFour = Math.floor(Math.random() * 10000).toString().padStart(4, '0')
      let lastFour = Math.floor(Math.random() * 10000).toString().padStart(4, '0')
      return `${firstFour}-${lastFour}`
    },
    
    async handleButtonClick() {
      switch(this.sessionState) {
        case 'initial':
          this.generatedTarget = await this.targetGenerator()
          this.buttonTitle = "Reveal Image"
          this.sessionState = 'generated'
          break
          
        case 'generated':
          try {
            const imageData = await getRandomImage(this.selectedTag || 'random')
            if (imageData) {
              this.imageSrc = imageData.url
              this.photographer = imageData.photographer
              this.photographerUrl = imageData.photographerUrl
              this.showImage = true
              this.buttonTitle = "Reset Session"
              this.sessionState = 'revealed'
            }
          } catch (error) {
            console.error('Failed to load image:', error)
          }
          break
          
        case 'revealed':
          this.resetSession()
          break
      }
    },

    resetSession() {
      this.generatedTarget = "Welcome User"
      this.buttonTitle = "Generate Target ID"
      this.imageSrc = null
      this.showImage = false
      this.sessionState = 'initial'
      this.photographer = ''
      this.photographerUrl = ''
      this.selectedTag = null
    }
  }
}
</script>

<style scoped>
.welcome-container {
  padding: 2rem;
  text-align: center;
  background: linear-gradient(180deg, rgba(13,12,19,0.8) 0%, rgba(20,19,28,0.9) 100%);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  margin: 2rem auto;
}

h1 {
  color: #e2e8f0;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  text-shadow: 0 0 15px rgba(157, 113, 234, 0.3);
}

.target-image {
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.target-image:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 25px rgba(157, 113, 234, 0.2);
}

.action-button {
  background: linear-gradient(45deg, #9d71ea, #67e8f9);
  color: #0f0e17;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(157, 113, 234, 0.3);
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(157, 113, 234, 0.4);
}

.action-button:active {
  transform: translateY(0);
}

.image-container {
  margin: 2rem 0;
}

.image-source {
  display: block;
  margin-top: 0.5rem;
  color: #9ca3af;
  font-size: 0.9rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.image-source:hover {
  color: #67e8f9;
}

.tags-container {
  margin: 2rem 0;
  background: rgba(15, 14, 23, 0.6);
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: 1px solid rgba(157, 113, 234, 0.1);
}

.expand-button {
  width: 100%;
  padding: 1rem;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e2e8f0;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.expand-button:hover {
  background: rgba(157, 113, 234, 0.1);
}

.arrow {
  display: inline-block;
  transition: transform 0.3s ease;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #9d71ea;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.tags-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease;
  padding: 0 1rem;
}

.tags-content.expanded {
  max-height: 500px;
  padding: 1rem;
  border-top: 1px solid rgba(157, 113, 234, 0.1);
}

.tags-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.8rem;
  justify-content: center;
}

.tag-label {
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 20px;
  background: rgba(15, 14, 23, 0.6);
  border: 2px solid rgba(157, 113, 234, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-label:hover {
  background: rgba(157, 113, 234, 0.1);
  border-color: rgba(157, 113, 234, 0.4);
}

.tag-label.selected {
  background: rgba(157, 113, 234, 0.15);
  border-color: #9d71ea;
  box-shadow: 0 0 15px rgba(157, 113, 234, 0.2);
}

.tag-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.tag-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: #e2e8f0;
}

/* Add glow effect to selected tag */
.tag-label.selected .tag-text {
  color: #67e8f9;
  text-shadow: 0 0 10px rgba(103, 232, 249, 0.3);
}

@media (max-width: 480px) {
  .expand-button {
    font-size: 1rem;
    padding: 0.8rem;
  }
  
  .welcome-container {
    margin: 1rem;
    padding: 1.5rem;
  }
  
  h1 {
    font-size: 2rem;
  }
}
</style>
